import React from "react";
import Hero from "../components/Homepage/Hero";

export default function Home() {
  return (
    <main className="flex items-center h-[calc(100vh-64px)] relative z-10">
      <Hero />
    </main>
  );
}
