import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';

export const getPortfolioItems = async () => {
  try {
    const portfolioRef = collection(db, 'PortfolioItems');
    console.log('Collection reference:', portfolioRef);

    const querySnapshot = await getDocs(portfolioRef);
    console.log('Query snapshot size:', querySnapshot.size);

    const portfolioItems = [];
    querySnapshot.forEach((doc) => {
      console.log('Document data:', doc.data());
      portfolioItems.push({
        id: doc.id,
        img: doc.data().img,
        url: doc.data().url,
        title: doc.data().title,
      });
    });

    console.log('Portfolio items:', portfolioItems);
    return portfolioItems;
  } catch (error) {
    console.error('Error fetching portfolio items:', error);
    throw error;
  }
};
