import React, { useState } from 'react';

const RetroTVWebView = ({ 
  url, 
  buttonText = 'Preview', 
  title = 'External Website',
  img,
  width = '90%',
  height = '80vh',
  zoom = 0.55  // New prop for controlling zoom level
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openPopup = () => setIsOpen(true);
  const closePopup = () => setIsOpen(false);
  const handleLoad = () => setIsLoading(false);

  // Calculate the inverse scale to compensate for zoom
  const inverseScale = 1 / zoom;
console.log('url:', url);
console.log('img:', img);
  return (
    <div className="relative">
      <div
        className="flex items-center justify-center w-full"
      >
        <div
          className="relative w-[300px] h-[200px] lg:w-[600px] lg:h-[400px] bg-black rounded-lg shadow-lg overflow-hidden"
        >
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="absolute inset-0 bg-black/20" />
          <div
            className="relative px-6 py-4  text-center z-40 h-full flex flex-col gap-2 items-center"
          >
            <button className="text-md text-white font-bold uppercase bg-secondary px-4 py-2 rounded-lg user-select-none mt-auto" onClick={openPopup}>
              {buttonText}
            </button>
            <h3 className="text-lg bg-black/40 px-4 py-2 rounded-lg font-semibold text-white">{title}</h3>
          </div>
          <div
            className="opacity-0 hover:opacity-100 transition-opacity ease-in-out duration-300 absolute inset-0 bg-black bg-opacity-50"
          ></div>
        </div>
      </div>

      {isOpen && (
        <div 
          className="fixed inset-0 z-[60] flex items-center justify-center bg-black bg-opacity-80 p-4 pt-24"
          onClick={closePopup}
        >
          <div 
            className="relative"
            onClick={(e) => e.stopPropagation()}
            style={{ width, height: 'auto' }}
          >
            {/* TV Frame */}
            <svg viewBox="0 0 800 700" className="w-full h-auto grayscale-[40%]">
              {/* TV Body */}
              <rect x="50" y="50" width="700" height="500" rx="20" fill="#614126" />
              <rect x="70" y="70" width="660" height="460" rx="15" fill="#3a2616" />
              
              {/* Screen Bezel */}
              <rect x="100" y="100" width="600" height="400" rx="30" fill="#1a1a1a" />
              
              {/* Decorative Elements */}
              <circle cx="400" cy="580" r="30" fill="#614126" /> {/* Base */}
              <rect x="300" y="550" width="200" height="60" fill="#614126" /> {/* Stand */}
              
              {/* Control Panel */}
              <circle cx="700" cy="250" r="20" fill="#1a1a1a" /> {/* Volume Knob */}
              <circle cx="700" cy="320" r="20" fill="#1a1a1a" /> {/* Channel Knob */}
              
              {/* Antenna */}
              <path d="M300 50 L250 0 M500 50 L550 0" stroke="#614126" strokeWidth="8" />
            </svg>

            {/* Screen Content Container */}
            <div className="absolute" style={{ 
              top: '14%', 
              left: '12.5%', 
              width: '75%', 
              height: '58%',
              borderRadius: '20px',
              overflow: 'hidden'
            }}>
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-white"></div>
                </div>
              )}
              
              {/* Screen Overlay Effects */}
              <div className="absolute inset-0 pointer-events-none" style={{
                background: 'linear-gradient(180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 50%, rgba(0,0,0,0.2) 100%)',
                zIndex: 2
              }} />
              
              {/* Iframe Container with Transform */}
              <div style={{
                width: `${inverseScale * 100}%`,
                height: `${inverseScale * 100}%`,
                transform: `scale(${zoom})`,
                transformOrigin: '0 0',
              }}>
                <iframe
                  title={title}
                  src={url}
                  className="w-full h-full border-none bg-black"
                  onLoad={handleLoad}
                  allowFullScreen
                  sandbox="allow-scripts allow-same-origin allow-forms"
                />
              </div>
            </div>

            {/* Close Button */}
            <button 
              onClick={closePopup}
              className="absolute top-4 right-4 text-white bg-red-500 rounded-full w-8 h-8 flex items-center justify-center hover:bg-red-600"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RetroTVWebView;