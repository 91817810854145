import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Header from "../components/Main/Header";
import Footer from "../components/Main/Footer";
import Projects from "../pages/Projects";

const AppRouter = () => {
  return (
    <Router>
      <div className="bg-background w-auto h-auto">
        <Header />
        <div className="fixed inset-4  bg-secondary/20 blur-md rounded-4xl"></div>
        <div className="fixed inset-4 bg-primary/20 blur-md rounded-4xl"></div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
          </Routes>
        <Footer />
        </div>
    </Router>
  );
};

export default AppRouter;
